
.about__hero__title {
    position: relative;
    z-index: 1;
}
.page--about section {
    position: relative;
    padding: 100px 0;
}
.page--about h2 {
    margin-bottom: 1rem;
}
.page--about .section--inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}
#about__hero {
    padding-bottom: 150px;
}

.about__space {
    background-color: var(--gold);
    color: var(--white);
}

.about__space h2 {
    color: var(--white);
}
.about__space__wrapper,
.about__coffee-and-food__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.about__space__wrapper {
    flex-wrap: wrap-reverse;
}
.about__space__image,
.about__coffee-and-food__image {
    width: 40%;
    height: auto;
    position: relative;
}

.about__coffee-and-food__image::after,
.about__space__image::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 20px;
    left: -20px;
    border: 2px solid var(--gold);
}
.about__space__image::after {
    left: unset;
    right: -20px;
    border-color: var(--white);
}

.about__coffee-and-food__image img,
.about__space__image img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
}

.about__coffee-and-food__text,
.about__space__text {
    width: 50%;
}


.about__coffee-and-food {
    background-color: var(--white);
}

.about__visit {
    background-color: var(--darkPink);
    color: var(--white);
}

.about__visit h2 {
    color: var(--white);
}

.about__reviews {
    padding: 2rem 0;
    background-color: #f8f8f8;
  }
  .about__reviews {
    padding: var(--spacingXL) 0;
    position: relative;
    overflow: hidden;
  }

  .reviews__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacingMedium);
    margin-top: var(--spacingLarge);
    margin-bottom: var(--spacingLarge);
  }

  .review {
    border-radius: var(--borderRadiusMedium);
    box-shadow: var(--shadowMedium);
    padding: var(--spacingMedium);
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
    overflow: hidden;
  }

  .review::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, var(--gold), var(--darkPink));
  }

  .review__text {
    flex-grow: 1;
    position: relative;
  }

  .quote-icon {
    position: absolute;
    top: -10px;
    left: -10px;
    opacity: 0.1;
    height: 60px;
    width: auto;
  }

  .review__text p {
    position: relative;
    z-index: 1;
  }

  .review__author {
    margin-top: var(--spacingSmall);
    font-weight: var(--bold);
    font-style: italic;
  }

  .reviews-cta {
    margin: var(--spacingMedium) auto;
    display: block;
  }
  .reviews__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: var(--tablet)) {
    .reviews__list {
      grid-template-columns: 1fr;
    }
  }

@media(max-width: 900px) {
    .about__hero__title {
        text-align: center;
        font-size: 3rem;
    }
    .about__space__wrapper {
        flex-direction: column-reverse;
    }
    .about__coffee-and-food__wrapper {
        flex-direction: column;
    }
    .section--inner {
        width: 90%;
        max-width: 90%;
        margin: 2rem auto;
        text-align: center;
    }
    .about__coffee-and-food__image,
    .about__coffee-and-food__text,
    .about__space__image,
    .about__space__text {
        width: 100%;
        margin: 2rem auto;
    }
    
    .about__coffee-and-food__image,
    .about__space__image {
        max-width: 300px;
    }
}
@media(max-width: 420px) {
    .about__hero__title {
        font-size: 2rem;
    }
}