.footer {
    background-color: var(--lightBlue);
    color: var(--white);
    text-align: center;
    padding: var(--spacingXL) var(--spacingMedium);
    font-weight: var(--bold);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.footer__link {
    color: var(--darkGreen);
    text-decoration: none;
    transition: var(--transition);
}