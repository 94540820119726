.navbar {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding: 0 var(--spacingMedium);
  background-color: var(--darkPink);
  box-shadow: var(--shadowMedium);
  z-index: var(--zIndexHigh);
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo__text {
  font-size: var(--fontXL);
  font-weight: var(--regular);
  color: var(--white);
  font-family: var(--yoehline);
  transform: rotate(-7deg);
  position: relative;
  display: block;
}

.nav__email {
  display: flex;
  align-items: center;
  color: var(--white);
  justify-content: center;
  transition: var(--transition);
  padding: 0 1rem;
}

.nav__email:hover {
  color: var(--darkGreen);
}

.nav__menu {
  display: flex;
  list-style: none;
  z-index: 9;
}

.nav__menu .logo {
  padding: 1rem 2rem;
  margin-top: -50px;
  display: none;
}

.nav__menu li a {
  color: var(--white);
  text-decoration: none;
  padding: var(--spacingMedium) var(--spacingMedium);
  position: relative;
  display: block;
  font-family: var(--body);
  font-weight: var(--bold);
  transition: var(--transition);
}

.nav__menu li a:hover {
  color: var(--darkPink);
  background-color: var(--white);
}

.hello-bar {
  background-color: var(--darkGreen);
  color: var(--gold);
  text-align: center;
  padding: 0.5rem 0;
  font-family: var(--body);
  font-size: var(--fontSmall);
  box-shadow: var(--shadowMedium);
  position: relative;
  z-index: 9;
  font-weight: 600;
}

/* Hamburger nav__menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger span {
  height: 2px;
  width: 25px;
  background-color: #fff;
  margin-bottom: 5px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Hide last span to create 'X' */
.hamburger.active span:nth-child(3) {
  opacity: 0;
}

.hamburger.active span:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.hamburger.active span:nth-child(2) {
  transform: translateY(-1px) rotate(-45deg);
}

/* Responsive */
@media (max-width: 1200px) {
  .navbar {
    padding: 1rem var(--spacingMedium);
  }

  .nav__email {
    justify-content: flex-start;
    padding: 0 2rem;
    margin-top: 1rem;
  }

  .nav__menu {
    position: fixed;
    top: 0;
    left: -100%;
    flex-direction: column;
    background-color: var(--lightGreen);
    width: 400px;
    height: 100vh;
    justify-content: flex-start;
    padding-top: 20px;
    transition: left 0.3s ease;
    max-width: 80%;
    padding-top: 15%;
    gap: 20px;
  }

  .nav__menu.active .logo {
    display: block;
  }

  .nav__menu.active {
    left: 0;
    z-index: 9; /* Corrected here */
  }

  .hamburger {
    display: flex;
  }

  .nav__menu li a {
    padding: 0 var(--spacingMedium);
  }
}
