.menu__hero {
  padding: var(--spacingXL) var(--spacingSmall);
  background-color: var(--lightBlue);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.menu__hero h1 {
  color: var(--navy);
  text-align: center;
  margin: 0;
}
.menu {
  display: block;
  list-style: none;
  gap: 20px;
  position: relative;
  z-index: 6;
  padding: 40px 0;
}
#drinks,
#food,
#pastries {
  margin-top: 60px;
}
/* menu */
.menu__section-title {
  font-size: 4rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 10px;
}
.disclaimer {
  font-size: 0.9rem;
  font-weight: 500;
  font-style: italic;
  margin: 0;
  padding: 0 10px;
  width: 60%;
  margin-bottom: 20px;
  padding: 0 10px;
}

.menu-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  cursor: pointer;
}

.tab-button:hover {
  background-color: #45a049;
}

.tab-content {
  display: flex !important;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}
.menu {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.menu-items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.menu-item {
  width: calc(100% / 3 - 1px);
  padding: var(--spacingSmall);
  border-radius: var(--borderRadius);
  position: relative;
  padding: var(--spacingSmall);
}
.menu-item--inner {
  background-color: var(--white);
  padding: var(--spacingSmall);
  border-radius: var(--borderRadius);
  border: 1px solid var(--lightBlue);
  position: relative;
  padding: var(--spacingSmall);
}
.menu-item {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.menu-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background-color: var(--white);
  min-height: 100%;
}

.menu-item-image-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
}

.menu-item-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px 10px 0 0;
}

.menu-item-details {
  padding: 0px 15px 25px 15px;
  color: var(--dark);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.menu-item-title {
  color: var(--dark);
  margin: 0;
  position: relative;
  padding: 1rem;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  font-size: 14px;
  text-align: center;
}
.menu-item-cta {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  width: fit-content;
}

.menu-item-description {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.menu-item-price {
  font-weight: 600;
  color: var(--white);
  background-color: var(--gold);
  padding: 8px;
  border-radius: 20px;
  border: 2px solid var(--gold);
  font-size: 12px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-button {
  text-decoration: none;
  background-color: var(--navy);
  color: var(--white);
  padding: 10px 15px;
  border-radius: 5px;
  display: inline-block;
}

.menu-item-tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  position: absolute;
  top: 0;
  z-index: 3;
}

.tag {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-left: 5px;
  font-size: 0.75rem;
  border: 2px solid var(--gold);
  position: relative;
  z-index: 3;
  position: relative;
}

.asterisk-tooltip {
  background-color: inherit;
  color: inherit;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 16px;
  width: 200px;
  z-index: 1000;
  word-wrap: break-word;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

@media (max-width: 1200px) {
  .asterisk-tooltip {
    transform: translateX(calc(-100% + 25px));
  }
}
.tag.vegetarian,
button.vegetarian,
.tag.coffee,
button.coffee {
  background-color: var(--gold);
  color: var(--white);
}

.tag.vegan,
button.vegan {
  background-color: var(--darkPink);
}

.tag.dairy-free,
button.dairy-free,
.tag.other,
button.other {
  background-color: var(--navy);
  color: var(--white);
}

.tag.nut-free,
button.nut-free {
  background-color: var(--lightGreen);
  color: var(--white);
}
.tag.tea,
button.tea {
  background-color: var(--darkPink);
}
.tag.gluten-sensitive,
button.gluten-sensitive,
.tag.gluten-sensitive-available,
button.gluten-sensitive-available,
.tag.specialty,
button.specialty,
.tag.gluten-free,
button.gluten-free {
  background-color: var(--midGreen);
  color: var(--white);
}
.filter-button-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 10px;
  flex-wrap: wrap;
}
.filter-button-group button {
  border-radius: 100px;
  padding: 10px;
  margin: 5px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  box-shadow: var(--shadowLarge);
  transition: 0.2s;
  border: 2px solid white;
}

.filter-button-group button:focus,
.filter-button-group button:hover {
  box-shadow: none;
  transform: translateY(-2px);
}

.filter-button-group button.active {
  border-color: var(--gold);
  box-shadow: none;
}
/* add an overlay to pastry images that says "Image coming soon" */
.menu-item-image-wrapper {
  position: relative;
}

.menu-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* Adjust the opacity and color as needed */
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #000;
  padding: 1rem;
  text-align: center;
  font-weight: 600;
}

.menu-item-image {
  z-index: 2;
}
/* To be updated when we add ordering to site */
.order-cta {
  display: none;
}
@media (max-width: 768px) {
  .menu-item {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .menu-item {
    width: 100%;
  }
  .menu__section-title,
  .disclaimer,
  .menu-item-description {
    text-align: center;
    width: 100%;
  }
  .menu-item-cta {
    left: 50%;
    transform: translateX(-50%);
  }
}
