/* root colors */
/* images are ../images/placeholders/imageurl.png */

/* import style_script from ../fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&family=Source+Sans+Pro:wght@300;400;700&display=swap');

/* src/styles/main.css */
@font-face {
  font-family: 'FoxAndBower';
  src: url('../assets/fonts/Fox-and-Bower/FoxAndBower.otf') format('opentype');
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Yoehline';
  src: url('../assets/fonts/Yoehline-Handwritten-Signature-Font/Yoehline Regular.ttf') format('truetype');
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
:root {
    --pink: #F9C3B5;
    --darkPink: #e7b8ad;
    --navy: #061148;
    --gold: #D3A13C;
    --veryLightGreen: #bac98640;
    --lightGreen: #798A42;
    --midGreen: #4C5E11;
    --darkGreen: #2F4E10;
    --white: #FFFFFF;
    --dark: #010101;
    --lightBlue: #c7dce2;
    
    /* font sizes */
    --fontSmall: .9rem;
    --fontMedium: 1.2rem;
    --fontLarge: 1.9rem;
    --fontXL: 2.8rem;
    --fontXXL: 4.5rem;

    /* font families */
    --foxAndBower: 'FoxAndBower', 'Montserrat', sans-serif;
    --poppins: 'Poppins', 'Montserrat', sans-serif;
    --yoehline: 'Yoehline', 'Montserrat', sans-serif;
    --body: 'Montserrat', 'Source Sans Pro', sans-serif;
    --heading:  'Montserrat', sans-serif;

    /* font weights */
    --light: 300;
    --regular: 400;
    --bold: 700;

    /* spacing */
    --spacingSmall: 1rem;
    --spacingMedium: 2rem;
    --spacingLarge: 4rem;
    --spacingXL: 6rem;
    --spacingXXL: 9rem;

    /* border radius */
    --borderRadiusSmall: 5px;
    --borderRadiusMedium: 10px;
    --borderRadiusLarge: 15px;

    /* box shadows */
    --shadowSmall: 0 3px 6px rgba(0, 0, 0, 0.1);
    --shadowMedium: 0 3px 6px rgba(0, 0, 0, 0.2);
    --shadowLarge: 0 3px 6px rgba(0, 0, 0, 0.3);

    /* z-index */
    --zIndexLow: 1;
    --zIndexMedium: 5;
    --zIndexHigh: 10;
    --zIndexMax: 100;

    /* media queries */
    --phone: 600px;
    --tablet: 900px;
    --desktop: 1200px;
    --largeDesktop: 1800px;

    /* transition */
    --transitionFast: all 0.2s ease-in-out;
    --transition: all 0.3s ease-in-out;

    /* misc */
    --maxWidth: 1200px;
}
html {
    scroll-behavior: smooth;
}
body {
    margin: 0;
    padding: 0;
    font-family: var(--body);
    font-size: var(--fontMedium);
    color: var(--dark);
    box-sizing: border-box;
}
body, html {
    overflow-x: clip;
}
p {
    line-height: 1.5;
    letter-spacing: 1px;
    font-size: var(--fontMedium);
}
h1, h2, h3, h4, h5, h6, p, li, a {
    z-index: 2;
}
h1, h2, h3, h4, h5, h6 {
    font-family: var(--heading);
    line-height: 1.2;
    letter-spacing: 1px;
}
h1 {
    font-size: var(--fontXXL);
    font-weight: var(--bold);
    color: var(--gold);
    text-transform: uppercase;
}
h2 {
    font-size: var(--fontXL);
    font-weight: var(--bold);
    color: var(--darkGreen);
}
h3 {
    font-size: var(--fontLarge);
    font-weight: var(--bold);
    color: var(--midGreen);
}

.cta {
    display: inline-block;
    padding: var(--spacingSmall) var(--spacingMedium);
    background-color: var(--midGreen);
    color: var(--white);
    font-weight: var(--bold);
    text-decoration: none;
    transition: var(--transition);
    position: relative;
    border-radius: 500px;
    text-transform: uppercase;
}
.cta--small {
    padding: .5rem 1rem;
    font-size: var(--fontSmall);
    line-height: 1.1;
}
.cta::after {
    content: "";
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px solid var(--gold);
    top: -4px;
    left: -4px;
    transition: var(--transitionFast);
    transform: rotate(7deg);
    border-radius: 500px;
}
.cta--small::after {
    top: -2px;
    left: -2px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
}
.cta:hover::after {
    top: 0;
    left: 0;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    transform: rotate(0deg);
}
.cta--secondary {
    background-color: var(--darkPink);
    color: var(--white);
}

/* Animations */
@keyframes floatAnimation {
    0% { transform: translate(0px, 0px) scale(1); }
    50% { transform: translate(30px, -20px) scale(1.05); }
    100% { transform: translate(0px, 0px) scale(1); }
  }
  
  .floating {
    animation: floatAnimation 6s ease-in-out infinite;
    will-change: transform;
  }

  .shape-divider {
    position: absolute;
    width: 100%;
    z-index: 3;
    left: 0;
}
.shape-divider--top {
    top: 0;
}
.shape-divider--bottom {
    bottom: -2px;
}
.shape--flipX {
    transform: rotateX(180deg);
}
.shape-divider--bottom .shape--flipX {
    bottom: -100px;
}
.custom-shape-divider {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 9;
}
.shape-divider--top .custom-shape-divider {
    top: 0;
}
.shape-divider--bottom .custom-shape-divider {
    bottom: 0;
}
.custom-shape-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100px;
}

.text--white {
    color: var(--white);
}
.text--gold {
    color: var(--gold);
}
.text--darkPink {
    color: var(--darkPink);
}
.text--navy {
    color: var(--navy);
}
.text--lightGreen {
    color: var(--lightGreen);
}
.text--midGreen {
    color: var(--midGreen);
}
.text--darkGreen {
    color: var(--darkGreen);
}
.text--lightBlue {
    color: var(--lightBlue);
}
.text--dark {
    color: var(--dark);
}

.bg--white {
    background-color: var(--white);
}
.bg--gold {
    background-color: var(--gold);
}
.bg--darkPink {
    background-color: var(--darkPink);
}
.bg--navy {
    background-color: var(--navy);
}
.bg--lightGreen {
    background-color: var(--lightGreen);
}
.bg--midGreen {
    background-color: var(--midGreen);
}
.bg--darkGreen {
    background-color: var(--darkGreen);
}
.bg--lightBlue {
    background-color: var(--lightBlue);
}
.bg--dark {
    background-color: var(--dark);
}