.hero {
  display: flex;
  justify-content: center;
  background-color: var(--white);
  color: var(--dark);
  text-align: center;
  position: relative;
}
.hero__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacingMedium);
  width: 35%;
  position: relative;
}
.hero__title {
  text-transform: uppercase;
}
.hero__title-1 {
  color: var(--lightGreen);
  font-size: var(--fontLarge);
  z-index: 1;
  font-weight: var(--light);
}
.hero__title-2 {
  color: var(--gold);
  font-size: var(--fontXXL);
  position: relative;
  font-weight: var(--bold);
}
.hero__title-2::after {
  content: "";
  position: absolute;
  width: 40px;
  border-top: 3px solid var(--pink);
  border-bottom: 3px solid var(--pink);
  right: -45px;
  top: calc(50% - 2px);
  height: 4px;
}
.hero__title-2::before {
  content: "";
  position: absolute;
  width: 40px;
  border-top: 3px solid var(--pink);
  border-bottom: 3px solid var(--pink);
  left: -45px;
  top: calc(50% - 2px);
  height: 4px;
}
.hero__title-3 {
  color: var(--lightGreen);
  font-size: var(--fontMedium);
}
.hero__text {
  font-size: var(--fontMedium);
  font-weight: var(--light);
  padding: var(--spacingMedium) 0;
  z-index: 2;
  font-weight: var(--medium);
}
.hero__media {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-height: 750px;
  z-index: 2;
}
.hero__media img {
  width: 100%;
}

/* section 2 */
.collectives {
  background-color: var(--lightBlue);
  color: var(--dark);
  padding: var(--spacingXL) var(--spacingMedium);
  position: relative;
}
.collectives--inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacingMedium);
  max-width: 1000px;
  margin: 0 auto;
  gap: var(--spacingMedium);
  text-align: center;
}
.collectives p > i {
  color: var(--midGreen);
  font-weight: var(--bold);
}
.collectives .blob,
.location .blob {
  z-index: 4;
}
.collectives h2,
.collectives p,
.collectives a,
.location h2,
.location p,
.location a {
  z-index: 5;
}

/* Menu */
.menu-section {
  background: linear-gradient(#fff, var(--lightBlue));
  display: flex;
  list-style: none;
  gap: 20px;
  padding: var(--spacingXXL) var(--spacingMedium) var(--spacingXL)
    var(--spacingMedium);
  position: relative;
}
.menu__wrapper {
  display: flex;
  align-items: center;
  padding: var(--spacingXL) var(--spacingMedium);
  max-width: 1200px;
  margin: 0 auto;
  gap: var(--spacingMedium);
}
.menu__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacingMedium);
  width: 35%;
  position: relative;
}
.menu__media {
  width: 65%;
  text-align: left;
  position: relative;
}
.menu__media img {
  width: 100%;
  height: auto;
  z-index: 5;
  position: relative;
}
.blob-link {
  width: 240px;
  height: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  top: 50%;
  left: 50%;
}
.drinks {
  transform: translate(-100%, -100%);
}
.food {
  transform: translate(0, 10%);
}
.pastries {
  transform: translate(100%, -100%);
}
.blob-link__image {
  width: 90%;
  height: auto;
  position: relative;
  z-index: 5;
  transition: var(--transition);
}
.blob-link__text {
  color: var(--darkGreen);
  font-weight: var(--bold);
  font-size: var(--fontLarge);
  position: relative;
  z-index: 6;
  display: block;
}
.blob-link .blob {
  transition: var(--transition);
}
.blob-link .blob {
  transform: scale(0.9) !important;
}
.blob-link:hover .blob {
  transform: scale(1.1) !important;
}
@keyframes wiggle {
  0%,
  100% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
}
.blob-link:hover .blob-link__image {
  animation: wiggle 1s infinite;
}

/* Location */

.location {
  background-color: var(--pink);
  color: var(--dark);
  padding: var(--spacingXL) var(--spacingMedium);
  position: relative;
}
.location--inner {
  display: flex;
  align-items: center;
  padding: var(--spacingMedium);
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
  flex-wrap: wrap;
}
.location--inner > h2 {
  width: 100%;
  text-align: center;
}
.location__graphic {
  width: 400px;
  height: 400px;
  min-width: 400px;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  margin-top: var(--spacingMedium);
  z-index: 5;
}
.location__content {
  width: calc(100% - 400px);
  position: relative;
  padding: var(--spacingLarge);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

@media screen and (max-width: 1440px) {
  .hero__content {
    width: 50%;
  }
  .hero__media {
    width: 50%;
  }
  .menu__wrapper {
    flex-direction: column;
  }
  .menu__content {
    width: 100%;
    max-width: 600px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .menu__media {
    width: 100%;
    min-height: 600px;
  }
  .location__graphic {
    width: 300px;
    height: 300px;
    min-width: 300px;
  }
  .location__content {
    width: calc(100% - 300px);
  }
  .blob-link {
    left: 30%;
  }
}
@media screen and (max-width: 1024px) {
  .location--inner {
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .location__content {
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 3rem 1rem;
  }
  .hero__media {
    display: none;
  }
  .hero__content {
    width: 100%;
    margin-bottom: 4rem;
  }
}
@media screen and (max-width: 768px) {
  
.collectives--inner,
.location__content,
.menu__wrapper {
    padding: 0;
  }
  .location__content {
    padding-top: 1rem;
  }
  .menu__media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  a.blob-link {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    transform: unset;
    margin-top: 4rem;
  }
}
